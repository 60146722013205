<template>
  <div class="scroll-cards">
    <img
      src="@/assets/img/scroll-anim/2/01.png"
      class="scroll-cards__bill"
      alt=""
    />
    <img
      src="@/assets/img/scroll-anim/2/02.png"
      class="scroll-cards__form"
      alt=""
    />
    <div class="scroll-cards__phone">
      <img src="@/assets/img/scroll-anim/1/phone.svg" class="img" />
      <img
        src="@/assets/img/scroll-anim/1/phone-card.svg"
        class="img scroll-cards__message"
      />
    </div>
  </div>
</template>

<script>
import { gsap, ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);

export default {
  name: "v-bill-to-form",
  mounted() {
    const timeline = gsap.timeline({
      scrollTrigger: {
        trigger: "#scroll-bill-to-form",
        start: "top +=200px",
        end: "+=1600px",
        pin: true,
        scrub: 0.2,
        defaults: { duration: 1, ease: "power1.inOut" },
      },
    });

    timeline
      .fromTo(
        ".scroll-cards__bill",
        {
          scale: 1,
          opacity: 1,
          left: 0,
        },
        {
          scale: 0.4,
          opacity: 0,
          duration: 0.5,
        }
      )
      .fromTo(
        ".scroll-cards__form",
        {
          opacity: 0,
          scale: 0.4,
        },
        {
          opacity: 1,
          scale: 1,
          duration: 0.5,
        },
        "-=0.5"
      )
      .to(".scroll-cards__bill", { opacity: 0 })
      .to(".scroll-cards__form", { opacity: 0, duration: 0.25 }, "+=0.5")
      .set(".scroll-cards__phone", { opacity: 0, duration: 0 }, "-=1")
      .to(".scroll-cards__phone", { opacity: 1, duration: 0.25 }, "-=0.3")
      .set(
        ".scroll-cards__message",
        { opacity: 0, scale: 0.9, duration: 0.75 },
        "-=1"
      )
      .to(".scroll-cards__message", {
        top: 360,
        scale: 0.9,
        opacity: 1,
      });
  },
};
</script>

<style lang="scss">
.scroll-cards {
  display: flex;
  flex-direction: column;

  &__bill,
  &__form {
    position: absolute;
    top: -50px;
    left: 0;
  }

  &__form {
    opacity: 0;
  }

  &__phone {
    position: absolute;
    top: -50px;
    left: 50%;
    opacity: 0;
    transform: scale(1) translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__message {
    top: 0;
    opacity: 0;
    position: absolute;
  }
}
</style>
